<template>
  <v-container>
    <v-card class="card_two">
      <v-row class="text-center ma-0 pa-0">
        <v-col class="ma-0">
          <v-banner
            color="#5cbbf6"
            class="white--text"
          >
            <v-row>
              <v-col cols="2">
                <v-img
                  :src="require('../assets/correos.png')"
                  class="my-3"
                  contain
                  height="100"
                />
              </v-col>
              <v-col cols="10">
                <h1
                  class="display-2 font-weight-bold mb-2"
                  style="color: rgb(238, 255, 0);"
                >
                  Correos de El Salvador
                </h1>
                SISTEMA INFORMÁTICO PARA LA GESTIÓN DE INVENTARIOS DE ESPECIES POSTALES Y 
                COMERCIO ELECTRÓNICO DE PRODUCTOS FILATÉLICOS
              </v-col>
            </v-row>
          </v-banner>
        </v-col>
      </v-row>
      <v-row
        class="fill-height"
        align="center"
        justify="center"
      >
        <template v-for="(item, i) in items">
          <v-col
            :key="i"
            cols="12"
            md="4"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
              >
                <v-img
                  :src="item.img"
                  height="255px"
                >
                  <v-card-title class="text-h6 white--text">
                    <v-row
                      class="fill-height flex-column"
                      justify="space-between"
                    >
                      <p class="mt-4 subheading text-left">
                        {{ item.title }}
                      </p>

                      <div>
                        <p class="ma-0 text-body-1 font-weight-bold font-italic text-left">
                          {{ item.text }}
                        </p>
                        <p class="text-caption font-weight-medium font-italic text-left">
                          {{ item.subtext }}
                        </p>
                      </div>

                      <div class="align-self-center">
                        <v-btn
                          v-for="(icon, index) in icons"
                          :key="index"
                          :class="{ 'show-btns': hover }"
                          :color="transparent"
                          icon
                        >
                          <v-icon
                            :class="{ 'show-btns': hover }"
                            :color="transparent"
                          >
                            {{ icon }}
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-row>
                  </v-card-title>
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
        </template>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",
  data: () => ({
      icons: [],
      items: [
        {
          title: '',
          text: ``,
          subtext: '',
          img: 'https://i.ebayimg.com/00/s/NDE5WDUyNw==/z/qGkAAOSwKzVlndU5/$_12.JPG?set_id=880000500F',
        },
        {
          title: '',
          text: '',
          subtext: '',
          img: 'https://thumbs.dreamstime.com/b/cancelled-postage-stamp-printed-salvador-shows-volcano-san-miguel-stars-overprinted-celebrating-years-postage-stamps-228678242.jpg',
        },
        {
          title: '',
          text: '',
          subtext: '',
          img: 'https://i.ebayimg.com/images/g/s8UAAOSwxbllndbt/s-l400.jpg',
        },
      ],
      transparent: 'rgba(255, 255, 255, 0)',
    }),
};
</script>
<style scoped>
.v-card {
  transition: opacity .2s ease-in-out;
  height: 5% !important;
  background: url('../assets/postal-image.jpg');
  background-size: cover;
}

.v-card:not(.on-hover) {
  opacity: 0.5;
  height: 5% !important;
 }

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
.v-chip {
  white-space: pre-wrap;
  height: 7vh !important;
}
</style>
