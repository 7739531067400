export default [
    {
        path: "/ordenes-compra",
        name: "ordenes-compra",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "ordenes-compra" */ "./OrdenCompraIndex.vue"),
      },
      {
        path: "/crear-ordenes-compra",
        name: "crear-ordenes-compra",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "crear-ordenes-compra" */ "./OrdenCompraCrear.vue"),
      },
      
      {
        path: "/ordenes-compra/ver/:id",
        name: "ordenes-compra-ver",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "ordenes-compra-ver" */ "./verOrdenCompraView.vue"),
      },
      {
        path: "/ordenes-compra/ver-pliegos/:id",
        name: "ordenes-compra-ver-pliegos",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "ordenes-compra-ver-pliegos" */ "../../components/detallePliegos/verDetallePliegos.vue"),
      },
      {
        path: "/facturas",
        name: "facturas",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "facturas" */ "./FacturaSelloPostalIndex.vue"),
      },
      {
        path: "/crear-facturas/:cliente/:id",
        name: "crear-facturas",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "crear-facturas" */ "./FacturaSelloPostal.vue"),
      },
      {
        path: "/factura/ver/:id",
        name: "factura-ver",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "factura-ver" */ "./verFacturaSelloPostalView.vue"),
      },
      {
        path: "/crear-notas-consignacion",
        name: "crear-notas-consignacion",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "crear-notas-consignacion" */ "../Filatelia/verNotaConsignacion.vue"),
      },
      {
        path: "/factura/crear-detalles-pliegos/:id",
        name: "detalle-pliegos",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "detalle-pliegos" */ "../../views/detallePliegos/crearView.vue"),
      }
]