export default [
    {
        path: "/resoluciones-juridicas",
        name: "resoluciones-juridicas",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "resoluciones-juridicas" */ "./IndexView.vue"),
      },
      {
        path: "/crear-resoluciones-juridicas",
        name: "crear-resoluciones-juridicas",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "crear-resoluciones-juridicas" */ "./CrearView.vue"),
      },
      {
        path: "/ver-resoluciones-juridicas/:id",
        name: "ver-resoluciones-juridicas",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "ver-resoluciones-juridicas" */ "./VerView.vue"),
      },
      {
        path: "/detalles-resoluciones-juridica/:id",
        name: "detalles-resoluciones-juridicas",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "detalles-resoluciones-juridicas" */ "./crearDetalleResolucion.vue"),
      },
]