export default [
      {
        path: "/inventarios",
        name: "inventarios",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "inventarios" */ "../../views/inventarios/inventarioView.vue"),
      },
      {
          path: "/inventario-crear",
          name: "inventario-crear",
          meta: { requiresAuth: true },
          component: () =>
            import(/* webpackChunkName: "inventario" */ "./inventarioCrear.vue"),
        },
        {
          path: "/inventario/ver/:id",
          name: "inventario-ver",
          meta: { requiresAuth: true },
          component: () =>
            import(/* webpackChunkName: "inventario" */ "../../views/inventarios/verView.vue"),
        },
]
