export default [
    {
        path: "/movimientos-bodega",
        name: "movimientos-bodega",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "movimientos-bodega" */ "../../views/movimientosBodegas/indexView.vue"),
      },
      {
        path: "/crear-movimientos-bodega",
        name: "crear-movimientos-bodega",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "crear-movimientos-bodega" */ "../../views/movimientosBodegas/crearView.vue"),
      },
      {
        path: "/movimientos-bodega/editar/:id",
        name: "editar-movimientos-bodega",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "editar-movimientos-bodega" */ "../../views/movimientosBodegas/crearView.vue"),
      },
      {
        path: "/movimientos-bodega/ver/:id",
        name: "ver-movimientos-bodega",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "ver-movimientos-bodega" */ "../../views/movimientosBodegas/verView.vue"),
      },
]