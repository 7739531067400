export default [
    {
        path: "/productos-filatelicos",
        name: "productos-filatelicos",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "productos-filatelicos" */ "../../views/prodFilatelicos/indexView.vue"),
      },
      {
        path: "/crear-productos-filatelicos",
        name: "crear-productos-filatelicos",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "crear-productos-filatelicos" */ "../../views/prodFilatelicos/crearView.vue"),
      },
      {
        path: "/productos-filatelicos/editar/:id",
        name: "editar-productos-filatelicos",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "editar-productos-filatelicos" */ "../../views/prodFilatelicos/crearView.vue"),
      },
      {
        path: "/productos-filatelicos/ver/:id",
        name: "ver-productos-filatelicos",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "ver-productos-filatelicos" */ "../../views/prodFilatelicos/verView.vue"),
      },

]