export default [
    {
        path: "/clientes",
        name: "clientes",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "clientes" */ "../../views/clientes/indexView.vue"),
      },
      {
        path: "/crear-clientes",
        name: "crear-clientes",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "crear-clientes" */ "../../views/clientes/crearView.vue"),
      },
      {
        path: "/clientes/editar/:id",
        name: "editar-clientes",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "editar-clientes" */ "../../views/clientes/crearView.vue"),
      },
      {
        path: "/clientes/ver/:id",
        name: "ver-clientes",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "ver-clientes" */ "../../views/clientes/verView.vue"),
      },
]