// numberToText.js
import Vue from 'vue';

Vue.prototype.$numberToText = function (number) {
  const ones = ['CERO', 'UNO', 'DOS', 'TRES', 'CUATRO', 'CINCO', 'SEIS', 'SIETE', 'OCHO', 'NUEVE'];
  const teens = ['ONCE', 'DOCE', 'TRECE', 'CATORCE', 'QUINCE', 'DIECISÉIS', 'DIECISIETE', 'DIECIOCHO', 'DIECINUEVE'];
  const tens = ['', 'DIEZ', 'VEINTE', 'TREINTA', 'CUARENTA', 'CINCUENTA', 'SESENTA', 'SETENTA', 'OCHENTA', 'NOVENTA'];
  const hundreds = ['', 'CIENTO', 'DOSCIENTOS', 'TRESCIENTOS', 'CUATROCIENTOS', 'QUINIENTOS', 'SEISCIENTOS', 'SETECIENTOS', 'OCHOCIENTOS', 'NOVECIENTOS'];
  const thousands = ['', 'MIL', 'DOS MIL', 'TRES MIL', 'CUATRO MIL', 'CINCO MIL', 'SEIS MIL', 'SIETE MIL', 'OCHO MIL', 'NUEVE MIL'];

  const convert = (num) => {
    if (num < 10) {
      return ones[num];
    } else if (num >= 11 && num <= 19) {
      return teens[num - 11];
    } else if (num >= 10 && num % 10 === 0) {
      return tens[num / 10];
    } else {
      return tens[Math.floor(num / 10)] + ' Y ' + ones[num % 10];
    }
  };

  const convertThreeDigits = (num) => {
    const hundredsDigit = Math.floor(num / 100);
    const remaining = num % 100;

    let text = '';
    if (hundredsDigit > 0) {
      text += hundreds[hundredsDigit] + ' ';
    }
    if (remaining > 0) {
      text += convert(remaining);
    } else if (text === '') {
      text = ones[0]; // Handle case of "000"
    }
    return text;
  };

  if (number === 0) {
    return ones[0];
  } else {
    const thousandsDigit = Math.floor(number / 1000);
    const remaining = number % 1000;

    let text = '';
    if (thousandsDigit > 0) {
      text += convertThreeDigits(thousandsDigit) + ' ' + thousands[thousandsDigit];
    }
    if (remaining > 0) {
      if (text !== '') {
        text += ' Y '; // Add separator if thousands present
      }
      text += convertThreeDigits(remaining);
    }
    return text.trim();
  }
};

Vue.prototype.$textToNumber = function (text) {
  const numberWords = {
    'cero': 0, 'uno': 1, 'dos': 2, 'tres': 3, 'cuatro': 4, 'cinco': 5,
    'seis': 6, 'siete': 7, 'ocho': 8, 'nueve': 9, 'diez': 10, 'once': 11,
    'doce': 12, 'trece': 13, 'catorce': 14, 'quince': 15, 'dieciseis':   
 16,
    'diecisiete': 17, 'dieciocho': 18, 'diecinueve': 19, 'veinte': 20,
    'treinta': 30, 'cuarenta': 40, 'cincuenta': 50, 'sesenta': 60,
    'setenta': 70, 'ochenta': 80, 'noventa': 90, 'cien':   
 100, 'mil': 1000
  };

  const words = text.toLowerCase().split(' ');
  let number = 0;

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (numberWords[word]) {
      number += numberWords[word];
    } else if (word === 'y') {
      // Handle 'y' as an addition operator
      continue;
    } else {
      // Handle more complex cases like hundreds, thousands, etc.
      // This is a simplified example and might require more complex logic
      if (word.endsWith('cientos')) {
        number += parseInt(word.replace('cientos', '')) * 100;
      } else if (word.endsWith('mil')) {
        number += parseInt(word.replace('mil', '')) * 1000;
      }
    }
  }

  return number;
};
