export default [
      {
        path: "/actas",
        name: "actas",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "actas" */ "../../views/actasEntrega/indexView.vue"),
      },
      {
        path: "/detalle-actas/:id",
        name: "detalle-actas",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "detalle-actas" */ "../../views/actasEntrega/crearDetalleView.vue"),
      },
      {
        path: "/actas/ver/:id",
        name: "actas-ver",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "actas-ver" */ "../../views/actasEntrega/verView.vue"),
      },
]