export default [
    {
      path: "/crear-solicitud-filatelia",
      name: "crear-solicitud-filatelia",
      meta: { requiresAuth: true },
      component: () =>
        import(/* webpackChunkName: "crear-solicitud-filatelia" */ "../../views/Filatelia/crearView.vue"),
    },
    {
      path: "/solicitudes-filatelia",
      name: "solicitudes-filatelia",
      meta: { requiresAuth: true },
      component: () =>
        import(/* webpackChunkName: "solicitudes-filatelia" */ "../../views/Filatelia/indexView.vue"),
    },
    {
      path: "/solicitud-filatelia/ver/:id",
      name: "solicitud-filatelia-ver",
      meta: { requiresAuth: true },
      component: () =>
        import(/* webpackChunkName: "solicitud-filatelia-ver" */ "./verSolicitudFilateliaView.vue"),
    },
    {
      path: "/solicitudes-filatelia/ver-pliegos/:id",
      name: "solicitudes-filatelia-ver-pliegos",
      meta: { requiresAuth: true },
      component: () =>
        import(/* webpackChunkName: "solicitudes-filatelia-ver-pliegos" */ "../../components/detallePliegos/verDetallePliegos.vue"),
    },
    {
      path: "/solicitudes-filatelia/ver-nota-consignacion/:id",
      name: "solicitudes-filatelia-ver-nota-consignacion",
      meta: { requiresAuth: true },
      component: () =>
        import(/* webpackChunkName: "solicitudes-filatelia-ver-nota-consignacion" */ "../../views/Filatelia/verNotaConsignacion.vue"),
    },
    {
      path: "/crear-solicitud-series-postales",
      name: "crear-solicitud-series-postales",
      meta: { requiresAuth: true },
      component: () =>
        import(/* webpackChunkName: "crear-solicitud-series-postales" */ "../../views/Filatelia/crearFCR00-122.vue"),
    },
    {
      path: "/solicitud-series-postales",
      name: "solicitud-series-postales",
      meta: { requiresAuth: true },
      component: () =>
        import(/* webpackChunkName: "solicitud-series-postales" */ "../../views/Filatelia/indexFCR00122.vue"),
    },
    {
      path: "/solicitud-series-postales/ver/:id",
      name: "solicitud-series-postales-ver",
      meta: { requiresAuth: true },
      component: () =>
        import(/* webpackChunkName: "solicitud-series-postales-ver" */ "../../views/Filatelia/verFCR00122View.vue"),
    },
    {
      path: "/venta-sobres",
      name: "venta-sobres",
      meta: { requiresAuth: true },
      component: () =>
        import(/* webpackChunkName: "venta-sobres" */ "../../views/Filatelia/indexVentaSobres.vue"),
    },
    {
      path: "/crear-venta-sobres",
      name: "crear-venta-sobres",
      meta: { requiresAuth: true },
      component: () =>
        import(/* webpackChunkName: "crear-venta-sobres" */ "../../views/Filatelia/crearVentaSobres.vue"),
    },
    {
      path: "/venta-sobres/ver/:id",
      name: "venta-sobres-ver",
      meta: { requiresAuth: true },
      component: () =>
        import(/* webpackChunkName: "venta-sobres-ver" */ "../../views/Filatelia/verVentaSobreView.vue"),
    },
    
    {
      path: "/crear-factura-consumidor-final",
      name: "crear-factura-consumidor-final",
      meta: { requiresAuth: true },
      component: () =>
        import(/* webpackChunkName: "crear-factura-consumidor-final" */ "../Filatelia/CrearFacturaConsumidor.vue"),
    },
    {
      path: "/factura-consumidor-final",
      name: "factura-consumidor-final",
      meta: { requiresAuth: true },
      component: () =>
        import(/* webpackChunkName: "factura-consumidor-final" */ "../Filatelia/FacturaConsumidorIndex.vue"),
    },
    {
      path: "/factura-consumidor/ver/:id",
      name: "factura-consumidor-ver",
      meta: { requiresAuth: true },
      component: () =>
        import(/* webpackChunkName: "factura-consumidor-ver" */ "./verFacturaConsumidorView.vue"),
    },
    {
      path: "/notas-consignacion",
      name: "notas-consignacion",
      meta: { requiresAuth: true },
      component: () =>
        import(/* webpackChunkName: "notas-consignacion" */ "../Filatelia/NotasIndex.vue"),
    },
    {
      path: "/notas-consignacion/ver/:id",
      name: "notas-consignacion-ver",
      meta: { requiresAuth: true },
      component: () =>
        import(/* webpackChunkName: "notas-consignacion-ver" */ "../Filatelia/verView.vue"),
    },

    
]