export default [
    {
        path: "/bodegas",
        name: "bodegas",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "bodegas" */ "../../views/bodegas/indexView.vue"),
      },
      {
        path: "/crear-bodegas",
        name: "crear-bodegas",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "crear-bodegas" */ "../../views/bodegas/crearView.vue"),
      },
      {
        path: "/bodegas/editar/:id",
        name: "editar-bodegas",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "editar-bodegas" */ "../../views/bodegas/crearView.vue"),
      },
      {
        path: "/bodegas/ver/:id",
        name: "ver-bodegas",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "ver-bodegas" */ "../../views/bodegas/verView.vue"),
      },
]