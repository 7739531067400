import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import clientesRoute from "../views/clientes/clientesRoute.js";
import acuerdosRoute from "../views/acuerdos/acuerdosRoute.js";
import actasRoute from "../views/actasEntrega/actasRoute.js";
import bodegasRoute from "../views/bodegas/bodegasRoute.js";
import cajasRoute from "../views/cajasFuertes/cajasRoute.js";
import carpetasRoute from "../views/carpetasFilatelicas/carpetasFilatelicasRoute";
import tarjetasPostalesRoute from "../views/tarjetasPostales/disenioRoute.js";
import prodFilRoute from "../views/prodFilatelicos/productosFiltelicosRoute.js";
import postalRoute from "../views/postal/PostalesRoute";
import facialRoute from "../views/facial/facialRoute";
import movRoute from "../views/movimientosBodegas/movientosRoute";
import detallesRoute from "../views/detallePliegos/detallePliegoRoute";
import invRoute from "../views/inventarios/inventariosRoute";
import ventasRoute from "../views/ventas/ventasRoute";
import estampillasSueltasRoute from "../views/estampillasSueltas/estampillasSueltasRoute";
import seriesEstampillasRoute from "@/views/seriesEstampillas/seriesEstampillasRoute";
import filateliaRoute from "../views/Filatelia/filateliaRoute";
import reportesRoute from "../views/Reportes/ReportesRoute";
import resolucionRoute from "../views/ResolucionJuridica/ResolucionesJuridicasRoute";
import sobresRoute from "../views/sobres/sobresRoute";
import userRoute from "../views/auth/authRoute";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
    meta: { requiresAuth: true },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/LoginView.vue"),
  },
  {
    path: "/forbidden",
    name: "forbiddenView",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "forbidden" */ "../views/ForbiddenView.vue"),
  },
  ...clientesRoute,
  ...acuerdosRoute,
  ...actasRoute,
  ...bodegasRoute,
  ...cajasRoute,
  ...carpetasRoute,
  ...tarjetasPostalesRoute,
  ...prodFilRoute,
  ...postalRoute,
  ...facialRoute,
  ...movRoute,
  ...invRoute,
  ...ventasRoute,
  ...estampillasSueltasRoute,
  ...seriesEstampillasRoute,
  ...filateliaRoute,
  ...reportesRoute,
  ...resolucionRoute,
  ...detallesRoute,
  ...sobresRoute,
  ...userRoute,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const routeExists = router.getMatchedComponents(to.path).length > 0;

  if (!routeExists) {
    next('/forbidden');
  } else {
    if (to.matched.some(route => route.meta.requiresAuth)) {
      const token = localStorage.getItem('token');

      if (!token) {
        next('/login');
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
