export default [
    {
        path: "/serie-estampillas",
        name: "serie-estampillas",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "serie-estampillas" */ "./indexSerieEstampillasView.vue"),
      },
      {
        path: "/crear-serie-estampillas",
        name: "crear-serie-estampillas",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "crear-serie-estampillas" */ "./SerieEstampillasCrear.vue"),
      }
]//