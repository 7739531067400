import Vue from 'vue';
Vue.prototype.$documentFormat = function(doc) {
    doc.forEach(element => {
        let lenghtDoc = element.Numero.length;
        if(lenghtDoc === 9){
            element.Numero = element.Numero[0] + element.Numero[1] + element.Numero[2] + element.Numero[3] + element.Numero[4] + element.Numero[5] +element.Numero[6] + element.Numero[7] + '-' + element.Numero[8];
        }
        if(lenghtDoc === 14){
            element.Numero = element.Numero[0] + element.Numero[1] + element.Numero[2] + element.Numero[3] + '-' + element.Numero[4] + element.Numero[5] +element.Numero[6] + element.Numero[7] + element.Numero[8] + element.Numero[9] + '-' + element.Numero[10] + element.Numero[11] + element.Numero[12] + '-' + element.Numero[13];
        }
        if(element.FechaEmision){
            let fecha = element.FechaEmision?.split(' ');
            element.FechaEmision = this.$parseDate(fecha[0])
        }
        if(element.FechaVencimiento){
            let fecha = element.FechaVencimiento?.split(' ');
            element.FechaVencimiento = this.$parseDate(fecha[0])
        }
    });
}
Vue.prototype.$documentFormatNumero = function(numero) {
    let lenghtDoc = numero.length;
    if(lenghtDoc === 9){
        numero = numero[0] + numero[1] + numero[2] + numero[3] + numero[4] + numero[5] +numero[6] + numero[7] + '-' + numero[8];
    }
    if(lenghtDoc === 14){
        numero = numero[0] + numero[1] + numero[2] + numero[3] + '-' + numero[4] + numero[5] +numero[6] + numero[7] + numero[8] + numero[9] + '-' + numero[10] + numero[11] + numero[12] + '-' + numero[13];
    }
    return numero;
}
Vue.prototype.$documentFormatNumeroTel = function(tels) {
    tels.forEach(element => {
        let lenghtDoc = element.Numero.length;
        if(lenghtDoc === 8){
            element.Numero = element.Numero[0] + element.Numero[1] + element.Numero[2] + element.Numero[3] + '-' + element.Numero[4] + element.Numero[5] +element.Numero[6] + element.Numero[7];
        }
    });
}
Vue.prototype.$documentFormatNumeroTelOne = function(tel) {
    let lenghtDoc = tel.length;
    if(lenghtDoc === 8){
        tel = tel[0] + tel[1] + tel[2] + tel[3] + '-' + tel[4] + tel[5] +tel[6] + tel[7];
    }
    return tel;
}