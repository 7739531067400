import VueJwtDecode from 'vue-jwt-decode'
import apiService from '../apiService';

const { api, apiWithFormData } = apiService;

export default {
  
    hasRole(requiredRole) {
      const token = localStorage.getItem('token');
      
      if (!token) {
        return false;
      }
  
      try {
        const decodedToken = VueJwtDecode.decode(token);
        return decodedToken.permissions.includes(requiredRole);
      } catch (error) {
        console.error('Error decoding JWT token:', error);
        return false;
      }
    },
    login(payload){
      return api.post('/api/login', payload);
    },

    logout(){
      return api.post('/api/logout');
    },

    userSession() {
      const token = localStorage.getItem('token');
      
      if (!token) {
        return false;
      }
  
      try {
        const decodedToken = VueJwtDecode.decode(token);
        return decodedToken.user;
      } catch (error) {
        this.$toast.error('Error decoding JWT token:', error);
        return false;
      }
    },

    //users
    indexUser(page, busqueda, catalogo){
      return api.get('/api/users?page='+page+'&search='+busqueda+'&catalogo='+catalogo);
    },
    saveUser(user){
      return api.post('/api/users', user);
    },
    updateUser(id,user){
      return api.put('/api/users/'+ id, user);
    },
    getUserId(id){
      return api.get('/api/users/'+ id);
    },
    deleteUser(id){
      return api.delete('/api/users/' + id)
    },
    indexRole(page, busqueda, catalogo){
      if(catalogo){
        return api.get('/api/roles?catalogo='+catalogo);
      }
      return api.get('/api/roles?page='+page+busqueda+'&catalogo='+catalogo);
    },
    saveRole(role){
      return api.post('/api/roles', role);
    },
    updateRole(id,role){
      return api.put('/api/roles/' + id, role);
    },
    getRoleId(id){
      return api.get('/api/roles/' + id);
    },
    deleteRole(id){
      return api.delete('/api/roles/' + id)
    },
    indexPermissions(page, busqueda, catalogo){
      if(catalogo){
        return api.get('/api/permissions?catalogo='+catalogo);
      }
      return api.get('/api/permissions?page='+page+busqueda+'&catalogo='+catalogo);
    },
    savePermission(permissions){
      return api.post('/api/permissions', permissions);
    },
    updatePermission(id,permissions){
      return api.put('/api/permissions/' + id, permissions);
    },
    getPermissionId(id){
      return api.get('/api/permissions/' + id);
    },
    deletePermission(id){
      return api.delete('/api/permissions/' + id)
    }
  };
  