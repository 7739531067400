export default [
    {
        path: "/acuerdos",
        name: "acuerdos",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "acuerdos" */ "../../views/acuerdos/indexView.vue"),
      },
      // {
      //   path: "/crear-acuerdos",
      //   name: "crear-acuerdos",
      //   meta: { requiresAuth: true },
      //   component: () =>
      //     import(/* webpackChunkName: "crear-acuerdos" */ "../../views/acuerdos/crearView.vue"),
      // },
      // {
      //   path: "/acuerdos/editar/:id",
      //   name: "editar-acuerdos",
      //   meta: { requiresAuth: true },
      //   component: () =>
      //     import(/* webpackChunkName: "editar-acuerdos" */ "../../views/acuerdos/crearView.vue"),
      // },
      {
        path: "/acuerdos/ver/:id",
        name: "ver-acuerdos",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "ver-acuerdos" */ "../../views/acuerdos/verView.vue"),
      },

      {
        path: "/acuerdos/crear-detalle/:id",
        name: "detalle-acuerdos",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "detalle-acuerdos" */ "../../views/acuerdos/crearDetalleView.vue"),
      },

]