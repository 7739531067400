export default [
      {
        path: "/disenio-tarjeta-postales",
        name: "disenio-tarjeta-postales",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "disenio-tarjeta-postales" */ "../../views/tarjetasPostales/indexDisenioTarjetasView.vue"),
      },
      {
        path: "/tarjetas-postales",
        name: "tarjetas-postales",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "tarjetas-postales" */ "../../views/tarjetasPostales/indexTarjetasPostalesView.vue"),
      },
]