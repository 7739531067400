import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import vuetify_icon from 'vuetify/dist/vuetify.min.css';
import vMask from "v-mask";
import VueTheMask from 'vue-the-mask'
import "./plugins/vuetify-money.js";
import '../utils/numbertToText';
import '../utils/validateAuth';
import '../utils/parseDate';
import '../utils/documentFormat';
import '../utils/menuService'
import Toasted from 'vue-toastification';
import 'vue-toastification/dist/index.css';

Vue.config.productionTip = false;

new Vue({
  vuetify_icon,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(vMask);
Vue.use(VueTheMask);
Vue.use(Toasted);