// apiService.js
import axios from 'axios';
const token = localStorage.getItem('token');
let url = process.env.VUE_APP_API_URL;
const api = axios.create({
    baseURL: url,
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer '+ token,
    }
  });
const apiWithFormData = axios.create({
    baseURL: url,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': 'Bearer '+ token,
      'Access-Control-Allow-Credentials': true,
      'Access-Control-Allow-Origin': process.env.VUE_APP_API_URL | '*'
    }
  });
export default { api, apiWithFormData };