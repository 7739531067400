export default [
    {
        path: "/cajas-fuertes",
        name: "cajas-fuertes",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "cajas-fuertes" */ "../../views/cajasFuertes/indexView.vue"),
      },
      {
        path: "/cajas-fuertes/ver/:id",
        name: "ver-cajas-fuertes",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "ver-cajas-fuertes" */ "../../views/cajasFuertes/verView.vue"),
      },
      {
        path: "/asignar-caja-fuerte",
        name: "asignar-caja-fuerte",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "asignar-caja-fuerte" */ "../../views/cajasFuertes/indexAsignaciones.vue"),
      },
      {
        path: "/crear-asignacion-caja-fuerte",
        name: "crear-asignacion-caja-fuerte",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "crear-asignacion-caja-fuerte" */ "../../views/cajasFuertes/asignacionCajaFuerte.vue"),
      },
      {
        path: "/editar-asignacion-caja-fuerte/:id",
        name: "editar-asignacion-caja-fuerte",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "editar-asignacion-caja-fuerte" */ "../../views/cajasFuertes/asignacionCajaFuerte.vue"),
      },
]