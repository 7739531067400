<template>
  <div>
    <layout
      v-if="show_"
      :authenticated="authenticated "
      :fn_mostrar="fnMostrar"
    />
  </div>
</template>
<script>
 import layout from '../src/components/menuBuilder/menuBuild.vue'
 import '../src/assets/styles/styles.css'

export default {
  
    components: {
      layout,
    },
  data() {
    return {
      show_:true,
    };
  },
  computed: {
    authenticated() {
      // Check if the user is authenticated, for example, by checking the presence of a JWT token
      return localStorage.getItem('token') !== null;
    },
  },
  watch: {
    },
    async mounted() {
    },
    methods: {
    fnMostrar(){
      this.$router.push('/login')
    }

    }
  };
</script>
