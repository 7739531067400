<template>
  <div v-if="compMostrar">
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        persistent
        width="500"
        class="height_alert"
        transition="dialog-top-transition"
      >
        <v-card
          height="35%"
          width="96%"
          class="ml-3 pl-3"
        >
          <v-card-title class="text-h5">
            <v-row
              justify="center"
              class="ma-1 pa-1"
            >
              <v-img
                alt="loading"
                class="shrink mr-2"
                contain
                src="../../public/loading.gif"
                transition="scale-transition"
                width="15vh"
              />
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row
              justify="center"
              class="ma-1 pa-1"
            >
              <h1>Cargando ...</h1>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
    export default {
        props: {
            mostrar: Boolean,
        },
        data(){
          return {
            dialog:true,
          }    
        },
        computed: {
          compMostrar: function() {
            return this.$props.mostrar;
          },
        },

        watch: {
          
        },
          
    }
</script>
