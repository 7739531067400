import Vue from 'vue';
import user from '../services/authServices/authService'
import VueJwtDecode from 'vue-jwt-decode'

Vue.prototype.$verifyRole = function(role_) {
    return user.hasRole(role_);
}
Vue.prototype.$getUserSession = function(){
    return user.userSession();
}

Vue.prototype.$closeExpiredSession = function() {
    this.$toast.error('Su sesión ha expirado');
    localStorage.removeItem('token');
    if(window.location.pathname !== '/login') {
        this.$router.push('/login')
    }
    // this.$getMenuService();
}

Vue.prototype.$timeLimitSession = function() {
    const token = localStorage.getItem('token');
    if(token){
        const decodedToken = VueJwtDecode.decode(token);
        return new Date(parseInt(decodedToken.exp)*1000);
    }
    return new Date();
}
