export default [
    {
        path: "/detalles-pliegos",
        name: "detalles-pliegos",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "detalles-pliegos" */ "../../views/detallePliegos/indexView.vue"),
      },
      {
        path: "/crear-detalles-pliegos",
        name: "crear-detalles-pliegos",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "crear-detalles-pliegos" */ "../../views/detallePliegos/crearView.vue"),
      }
]