export default [
    {
      path: "/usuarios",
      name: "indexUser",
    // meta: { requiresAuth: true },
      component: () =>
        import(/* webpackChunkName: "users" */ "../auth/indexUserView.vue"),
    },
    {
      path: "/usuarios/ver/:id",
      name: "ver-user",
      //meta: { requiresAuth: true },
      component: () =>
        import(/* webpackChunkName: "ver-user" */ "../auth/verUserView.vue"),
    },
    {
      path: "/crear-usuarios",
      name: "creauser",
     // meta: { requiresAuth: true },
      component: () =>
        import(/* webpackChunkName: "crear-users" */ "../auth/crearView.vue"),
    },
    {
      path: "/usuarios/editar/:id",
      name: "editaruser",
     // meta: { requiresAuth: true },
      component: () =>
        import(/* webpackChunkName: "editar-users" */ "../auth/crearView.vue"),
    },
    {
        path: "/roles",
        name: "indexRole",
       // meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "roles" */ "../auth/indexRoleView.vue"),
      },
      {
        path: "/crear-roles",
        name: "crearole",
       // meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "crear-roles" */ "../auth/crearRoleView.vue"),
      },
      {
        path: "/roles/ver/:id",
        name: "ver-role",
        //meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "ver-role" */ "../auth/verRoleView.vue"),
      },
      {
        path: "/roles/editar/:id",
        name: "editarRole",
       // meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "editarRole" */ "../auth/crearRoleView.vue"),
      },
      {
        path: "/permissions",
        name: "indexPermissions",
       // meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "permissions" */ "../auth/indexPermissionView.vue"),
      },
      {
        path: "/crear-permission",
        name: "crearpermissions",
       // meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "crear-permissions" */ "../auth/crearPermissionView.vue"),
      },
      {
        path: "/permission/editar/:id",
        name: "editarPermissions",
       // meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "editarPermissions" */ "../auth/crearPermissionView.vue"),
      },
      {
        path: "/permission/ver/:id",
        name: "ver-permiso",
        //meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "ver-permissions" */ "../auth/verPermissionView.vue"),
      },
    
]